<template>
<el-dialog v-model="visible" title="下载条码" custom-class="batch-download-dialog" :before-close="close">
  <div class="bdd-left" v-loading="loading">
    <div class="bdd-title">基本信息</div>
    <div class="bddl-item">
      <div class="bddli-line row-center-start">
        <div>供应商：</div>
        <span>{{ formData.txtManufacturerName }}</span>
      </div>
      <div class="bddli-line row-center-start">
        <div>检测类型：</div>
        <span>{{ formData.txtCategoryName }}</span>
      </div>
    </div>
    <div class="bdd-title">批次信息</div>
    <div class="bddl-item">
      <div class="bddli-line row-center-start">
        <div>生产年份：</div>
        <span>{{ formData.txtBatchYear }}</span>
      </div>
      <div class="bddli-line row-center-start">
        <div>生产月份：</div>
        <span>{{ formData.txtBatchMonth }}</span>
      </div>
      <div class="bddli-line row-center-start">
        <div>生产批次：</div>
        <span>{{ formData.txtBatchSerial }}</span>
      </div>
    </div>
  </div>
  <div class="bdd-right column-center-center">
    <canvas id="canvascode"></canvas>
  </div>
</el-dialog>
</template>

<script>
// 批次管理 - 下载条码
import JsBarcode from 'jsbarcode';
import { onMounted, reactive, toRefs } from 'vue';
export default {
  props: {
    barData: { type: Object },
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      formData: {},
      option: {
        fontSize: 16,
        color: "#000000",
        width: 2.5
      },
      show: false,
    })

    onMounted(() => {
      state.loading = true;
      state.formData = prop.barData;
      setTimeout(() => {
        JsBarcode("#canvascode", prop.barData.txtBarCode, state.option)
        state.show = true;
        state.loading = false;
      }, 800)
    })

    const close = () => {
      ctx.emit("close");
    };

    return {
      ...toRefs(state),
      close,
    }
  }
}
</script>

<style lang="scss">
.batch-download-dialog {
  width: 45%;
  height: calc(100% - 50vh);

  .el-dialog__body {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bdd-title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .bdd-left {
      height: 100%;
      width: 50%;

      .bddl-item {
        padding: 6px 20px;

        .bddli-line {
          font-size: 14px;
          margin-bottom: 16px;

          div {
            width: 80px;
            font-weight: bold;
          }
        }
      }
    }

    .bdd-right {
      width: 45%;
      height: 100%;

      #canvascode {
        width: 100%;
      }
    }
  }
}
</style>
