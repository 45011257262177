<template>
<el-dialog v-model="visible" :title="title" :close-on-click-modal="false" custom-class="batch-curve-dialog" :before-close="close">
  <el-tabs v-model="tabsValue" type="card" editable @edit="handleTabsEdit" :before-leave="tabChange" v-loading="loading">
    <el-tab-pane v-for="(item,index) in tabData" :key="item.lxcgCurveId" :label="item.lxcgCurveGroupName" :name="index"></el-tab-pane>
    <el-form :model="form" ref="ruleFormRef" :rules="rules">
      <!-- 已配置过的曲线组不可选择 -->
      <el-form-item label="选择曲线组：" prop="lxcgCurveGroupId">
        <el-select v-model="form.lxcgCurveGroupId" clearable placeholder="请选择曲线组" size="small">
          <el-option v-for="(sItem, sIndex) in options.lxcgCurveVersionOption" :disabled="sItem.bound!=-1&&sItem.bound!=form.lxcgCurveId" :key="sIndex" :label="sItem.txtLxcgCurveGroupName" :value="sItem.lxcgCurveGroupId" />
        </el-select>
      </el-form-item>
      <div class="bcd-params-part row-center-start">
        <div class="bcdpp-left">
          <div class="bcdpp-title">第一组参数（必填）：</div>
          <el-form-item label="中位线：" prop="intMidx">
            <el-input v-model="form.intMidx" placeholder="请输入中位线" maxlength="3" />
          </el-form-item>
          <el-form-item label="偏移量：" prop="intCtoffset">
            <el-input v-model="form.intCtoffset" placeholder="请输入偏移量" maxlength="3" />
          </el-form-item>
          <el-form-item label="参数a：" prop="fltA">
            <el-input v-model="form.fltA" placeholder="请输入参数a" />
          </el-form-item>
          <el-form-item label="参数b：" prop="fltB">
            <el-input v-model="form.fltB" placeholder="请输入参数b" />
          </el-form-item>
          <el-form-item label="参数c：" prop="fltC">
            <el-input v-model="form.fltC" placeholder="请输入参数c" />
          </el-form-item>
          <el-form-item label="参数d：" prop="fltD">
            <el-input v-model="form.fltD" placeholder="请输入参数d" />
          </el-form-item>
        </div>
        <div class="bcdpp-right">
          <div class="bcdpp-title">第二组参数（非必填）：</div>
          <el-form-item label="中位线：" prop="intMidx2">
            <el-input v-model="form.intMidx2" placeholder="请输入中位线" maxlength="3" />
          </el-form-item>
          <el-form-item label="偏移量：" prop="intCtoffset2">
            <el-input v-model="form.intCtoffset2" placeholder="请输入偏移量" maxlength="3" />
          </el-form-item>
          <el-form-item label="参数a：" prop="fltA2">
            <el-input v-model="form.fltA2" placeholder="请输入参数a" />
          </el-form-item>
          <el-form-item label="参数b：" prop="fltB2">
            <el-input v-model="form.fltB2" placeholder="请输入参数b" />
          </el-form-item>
          <el-form-item label="参数c：" prop="fltC2">
            <el-input v-model="form.fltC2" placeholder="请输入参数c" />
          </el-form-item>
          <el-form-item label="参数d：" prop="fltD2">
            <el-input v-model="form.fltD2" placeholder="请输入参数d" />
          </el-form-item>
        </div>
      </div>
    </el-form>

  </el-tabs>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close('cancel')">关 闭</el-button>
      <el-button type="primary" @click="close('confirm')">保 存</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 批次管理 - 新增/编辑
import { useStore } from 'vuex';
import { reactive, toRefs, ref, computed, onMounted } from "vue";
import { ElMessage, FormInstance, ElMessageBox } from 'element-plus';
import { getCurveList, getCurveVersionList, addCurve, updateCurve, deleteCurve } from "api/apis.js";
export default {
  props: {
    curveData: { type: Object },
  },
  setup(prop, ctx) {
    // 表单校验 
    const inputPattern = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/;
      // 如果是非必填数据
      if (rule.field.includes("2")) {
        // 判断是否有内容
        if (value) {
          // 有内容，校验
          if (rule.field == "intMidx2" || rule.field == "intCtoffset2") {
            // 中位线/偏移量 - 3位以内正整数
            if ((value || "").length > 3 || !reg.test(value)) {
              callback(new Error(`请输入有效的${rule.field == "intMidx2" ? "中位线" : "偏移量"}`));
            } else {
              callback();
            }
          } else {
            // a/b/c/d - 保留5位小数，可正可负
            reg = /^-?\d+(.\d{1,5})?$/;
            if (reg.test(value)) {
              callback();
            } else {
              let letter = rule.field.split("flt")[1].split("2")[0].toLowerCase();
              callback(new Error("请输入有效的参数" + letter));
            }
          }
        } else {
          callback();
        }
      } else {
        if (rule.field == "intMidx" || rule.field == "intCtoffset") {
          // 中位线/偏移量 - 3位以内正整数
          if ((value || "").length > 3 || !reg.test(value)) {
            callback(new Error(`请输入有效的${rule.field == "intMidx" ? "中位线" : "偏移量"}`));
          } else {
            callback();
          }
        } else {
          // a/b/c/d - 保留5位小数，可正可负
          reg = /^-?\d+(.\d{1,5})?$/;
          if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("请输入有效的参数" + rule.field.split("flt")[1].toLowerCase()));
          }
        }
      }
    };

    const store = useStore();
    const state = reactive({
      ruleFormRef: ref(FormInstance),
      visible: true,
      loading: false,
      form: {},
      title: computed(() => "曲线管理 - " + prop.curveData.txtBarCode),
      // 动态计算，取值为 tabData 的索引值
      tabsValue: 0,
      tabData: [],
      source: {
        lxcgCurveId: 0, //曲线ID
        lxcgCurveGroupId: "", //曲线组ID
        // 动态计算名字，-1 为索引值+1后得出的值
        lxcgCurveGroupName: "曲线配置-1", //第一组参数
        intMidx: "", //中位线
        intCtoffset: "", //偏移量
        fltA: "", //参数a
        fltB: "", //参数b
        fltC: "", //参数c
        fltD: "", //第二组参数
        intMidx2: "", //中位线
        intCtoffset2: "", //偏移量
        fltA2: "", //参数a
        fltB2: "", //参数b
        fltC2: "", //参数c
        fltD2: "" //参数d
      },
      tabContrastData: [],
      options: {
        lxcgCurveVersionOption: []
      },
      rules: {
        lxcgCurveGroupId: [{ required: true, message: "曲线组不可为空", trigger: "blur" }],
        intMidx: [
          { required: true, message: "中位线不可为空", trigger: "blur" },
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        intCtoffset: [
          { required: true, message: "偏移量不可为空", trigger: "blur" },
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltA: [
          { required: true, message: "参数a不可为空", trigger: "blur" },
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltB: [
          { required: true, message: "参数b不可为空", trigger: "blur" },
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltC: [
          { required: true, message: "参数c不可为空", trigger: "blur" },
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltD: [
          { required: true, message: "参数d不可为空", trigger: "blur" },
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        intMidx2: [
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        intCtoffset2: [
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltA2: [
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltB2: [
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltC2: [
          { required: true, validator: inputPattern, trigger: "blur" }
        ],
        fltD2: [
          { required: true, validator: inputPattern, trigger: "blur" }
        ]
      }
    });

    onMounted(() => {
      getCurves("first");
    })

    // 获取 配置过的曲线
    const getCurves = (type) => {
      state.loading = true;
      getCurveList({ lxcgBatchId: prop.curveData.lxcgBatchId }).then(response => {
        if (response.code == 200) {
          if ((response.data || []).length == 0) {
            state.tabData.push(JSON.parse(JSON.stringify(state.source)));
            state.tabData[0].lxcgCurveGroupName = "曲线配置-1";
          } else {
            state.tabData = response.data;
            // 对照组 深拷贝
            state.tabContrastData = JSON.parse(JSON.stringify(response.data));
          }
          // 考虑其他函数 重新请求的情况 
          if (type == "add" || type == "edit") {
            // 新增/编辑，成功后不改变 tabsValue 的值
            state.form = state.tabData[state.tabsValue];
          } else if (type == "first") {
            // 首次加载
            state.tabsValue = 0;
            state.form = state.tabData[state.tabsValue];
          }
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getCurveOption();
      })
    };

    // 获取曲线组 option
    const getCurveOption = () => {
      getCurveVersionList().then(response => {
        if (response.code == 200) {
          // 时刻监听 tab 状态，已配置过的不能再被选择
          state.options.lxcgCurveVersionOption = response.data.map(t => {
            let index = state.tabData.findIndex(a => a.lxcgCurveGroupId == t.lxcgCurveGroupId);
            return {
              txtLxcgCurveGroupName: t.txtLxcgCurveGroupName,
              lxcgCurveGroupId: t.lxcgCurveGroupId,
              bound: index == -1 ? -1 : state.tabData[index].lxcgCurveId
            }
          });
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // tab 切换事件
    const tabChange = async newTab => {
      let result = new Promise((resolve, reject) => {
        // 查重，如存在已修改的数据，不可切换
        let check = checking();
        // 查重成功，说明当前表单无修改，可直接切换
        if (check) {
          // 重新赋值
          state.tabsValue = newTab;
          state.form = state.tabData[state.tabsValue];
          // 清除 必填校验 状态
          if (state.form.lxcgCurveId != 0) {
            setTimeout(() => {
              state.ruleFormRef.validate();
            }, 100)
          }
          resolve();
        } else {
          // 查重失败，存在 已修改未保存 的值，阻止切换tab，显示提示box
          confirmBox();
          reject();
        }
      })
      return await result;
    };

    // tab 编辑事件
    // 切换tba 或关闭dialog时，判断是否存在已修改未保存的数据
    // hint：存在未保存的配置，请先保存
    const handleTabsEdit = (targetName, action) => {
      // 判断是否存在 已修改未保存 的数据
      let result = checking();
      // 新增 tab 
      if (action == "add") {
        // 如果配置组0索引无ID值，则不能添加新的配置
        // 有 ID 值，可新增
        if ((state.tabData[state.tabsValue].lxcgCurveId || "") != "") {
          if (result) {
            // 不存在 已修改未保存 的数据
            state.tabData.push(JSON.parse(JSON.stringify(state.source)));
            state.form = JSON.parse(JSON.stringify(state.source));
            state.tabsValue = state.tabData.length - 1;
            state.tabData[state.tabsValue].lxcgCurveGroupName = "曲线配置-" + state.tabData.length;
          } else {
            confirmBox();
          }
        }
      } else if (action == "remove") {
        // 删除事件
        // 仅有一个配置且为默认空配置
        if (state.tabData.length == 1 && (state.tabData[0].lxcgCurveId || "") == "") {
          console.log("不可删除");
        } else {
          // 要操作的 tab 不是 当前 tab
          if (targetName != state.tabsValue) {
            // 要删除的配置不是默认空数据
            if ((state.tabData[targetName].lxcgCurveId || "") != "") {
              // 直接删除 id， 数组长度，操作的索引 
              handleDelete(state.tabData, state.tabData.length, targetName);
            } else {
              state.tabData.splice(targetName, 1);
            }
          } else {
            // 要删除的是当前 tab
            // 要删除的配置不是默认空数据
            if ((state.tabData[targetName].lxcgCurveId || "") != "") {
              if (result) {
                // 查重通过
                // 如果只有一个配置，删除后注入默认空数据
                if (state.tabData.length == 1) {
                  // 直接删除 id， 数组长度，操作的索引
                  handleDelete(state.tabData, state.tabData.length, targetName);
                } else {
                  // 存在多个配置，删除一个后，展示下一个
                  handleDelete(state.tabData, state.tabData.length, targetName);
                }
              } else {
                // 显示 提示信息
                ElMessageBox.confirm(
                  "存在未保存的配置，确定要删除该配置吗？",
                  '提示', {
                    distinguishCancelAndClose: true,
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                  }
                ).then(() => {
                  handleDelete(state.tabData, state.tabData.length, targetName);
                }).catch(() => {
                  console.log("曲线配置 - 取消删除");
                })
              }
            } else {
              //  todo 要删除的配置 是默认空数据, 不考虑查重，直接清空表单
              // state.tabData[targetName] = JSON.parse(JSON.stringify(state.source));
              // state.form = state.tabData[targetName];
              // 要删除的配置 是默认空数据
              // 无修改，直接删除
              if (result) {
                state.tabData.splice(targetName, 1);
                state.tabsValue = state.tabsValue - 1;
                state.form = state.tabData[state.tabsValue];
              } else {
                // 存在修改
                ElMessageBox.confirm(
                  "存在未保存的配置，确定要删除该配置吗？",
                  '提示', {
                    distinguishCancelAndClose: true,
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                  }
                ).then(() => {
                  state.tabData.splice(targetName, 1);
                  state.tabsValue = state.tabsValue - 1;
                  state.form = state.tabData[state.tabsValue];
                }).catch(() => {
                  console.log("曲线配置 - 取消删除");
                })
              }
            }
          }
        }
      }
    };

    // 删除 曲线配置 ID，当前配置组长度，操作的索引
    const handleDelete = (data, length, index) => {
      ElMessageBox.confirm(
        `确认是否删除针对【${data[index].lxcgCurveGroupName}】曲线组的配置？`,
        '提示', {
          distinguishCancelAndClose: true,
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }
      ).then(() => {
        state.loading = true;
        deleteCurve({
          lxcgCurveId: data[index].lxcgCurveId,
          txtUpdater: store.getters.userInfo ? store.getters.userInfo.name : "系统用户"
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("删除成功");
            // 重新赋值
            if (length == 1) {
              // 配置组长度为1，展示默认数据
              state.tabData = [];
              state.tabData.push(JSON.parse(JSON.stringify(state.source)));
              state.tabsValue = 0;
              state.form = state.tabData[0];
            } else {
              // 配置组长度大于1
              state.tabData.splice(index, 1);
              state.tabContrastData.splice(index, 1);
              state.tabsValue = state.tabsValue - 1 < 0 ? 0 : state.tabsValue - 1;
              state.form = state.tabData[state.tabsValue];
            }
          } else {
            ElMessage.error(response.message);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          updateBound();
          state.loading = false;
        })
      })
    };

    const confirmBox = () => {
      ElMessageBox.confirm(
        "存在未保存的配置，请先保存",
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          showCancelButton: false
        }
      ).then(() => {});
    };

    // 验重
    const checking = () => {
      // 判断是否存在 对照组 数据，用 ID 查找
      state.tabData[state.tabsValue] = state.form;
      let index = state.tabContrastData.findIndex(t => t.lxcgCurveId == state.tabData[state.tabsValue].lxcgCurveId),
        result = true; // 两组数据的对比结果
      if (index != -1) {
        // 与 对照组 进行对比
        let item = state.tabData[state.tabsValue],
          contrastItem = state.tabContrastData[index];
        for (let i = 0; i < Object.keys(item).length; i++) {
          let key = Object.keys(item)[i];
          if (key != "lxcgCurveGroupName" && item[key] != contrastItem[key]) {
            result = false;
            break;
          }
        }
      } else {
        // 当前索引值的所有数据
        let values = Object.values(state.tabData[state.tabsValue]),
          // 找出 所有 为 空/空串 的值
          nullArr = values.filter(t => (t || "") == "");
        // 如果 nullArr.length == values.length-1，说明无修改，可直接关闭
        if (nullArr.length == values.length - 1) {
          result = true;
        } else {
          result = false;
        }
      }
      return result;
    };

    const close = (type) => {
      if (type == "confirm") {
        state.ruleFormRef.validate(valid => {
          if (valid) {
            // 校验成功
            state.loading = true;
            let param = {
              lxcgBatchId: prop.curveData.lxcgBatchId, //批次ID
              lxcgCurveGroupId: state.form.lxcgCurveGroupId, //曲线组ID
              intMidx: state.form.intMidx, //中位线
              intCtoffset: state.form.intCtoffset, //偏移量
              fltA: state.form.fltA, //参数a
              fltB: state.form.fltB, //参数b
              fltC: state.form.fltC, //参数c
              fltD: state.form.fltD, //参数d
              intMidx2: state.form.intMidx2, //中位线（第二组）
              intCtoffset2: state.form.intCtoffset2, //偏移量（第二组）
              fltA2: state.form.fltA2, //参数a（第二组）
              fltB2: state.form.fltB2, //参数b（第二组）
              fltC2: state.form.fltC2, //参数c（第二组）
              fltD2: state.form.fltD2 //参数d（第二组）
            }
            // 判断是新增还是更新
            // 当前索引值是否存在 ID，存在，为更新操作
            if (state.tabData[state.tabsValue].lxcgCurveId != "") {
              param.lxcgCurveId = state.form.lxcgCurveId;
              param.txtUpdater = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              updateCurve(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("更新成功");
                  // 保存成功，重新获取配置
                  getCurves("edit");
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            } else {
              // 当前索引值 不存在 ID 数据，为新增操作
              param.txtCreator = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              addCurve(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("保存成功");
                  // 保存成功，重新获取配置
                  getCurves("add");
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            }
          }
        });
      } else {
        // 关闭 dialog 前，校验是否存在未保存的数据
        let result = checking();
        if (result) {
          ctx.emit("close", false);
        } else {
          confirmBox();
        }
      }
    };

    // 更新曲线组绑定关系
    const updateBound = () => {
      state.options.lxcgCurveVersionOption = state.options.lxcgCurveVersionOption.map(t => {
        let target = state.tabData.findIndex(a => a.lxcgCurveGroupId == t.lxcgCurveGroupId);
        return {
          txtLxcgCurveGroupName: t.txtLxcgCurveGroupName,
          lxcgCurveGroupId: t.lxcgCurveGroupId,
          bound: target == -1 ? -1 : state.tabData[target].lxcgCurveId
        }
      });
    };

    return {
      ...toRefs(state),
      close,
      handleTabsEdit,
      getCurves,
      getCurveOption,
      checking,
      tabChange,
      confirmBox,
      updateBound,
      handleDelete
    }
  }
}
</script>

<style lang="scss">
.batch-curve-dialog {
  width: 50%;

  .el-dialog__body {
    padding: 20px 20px;
    box-sizing: border-box;
    height: calc(100% - 120px);

    .el-tabs {
      .el-tabs__header {
        margin-bottom: 0;

        .el-tabs__new-tab {
          background-color: #8a88c3;

          .el-icon {
            color: #fff;
          }
        }

        .el-tabs__nav-wrap {
          .el-tabs__nav {
            border-top-left-radius: 10px;
            border-bottom: 1px solid #e4e7ed;

            .el-tabs__item {
              &:hover {
                color: #8a88c3;
              }

              &.is-active {
                color: #8a88c3;
                font-weight: bold
              }
            }
          }
        }
      }

      .el-tabs__content {
        border: 1px solid #e4e7ed;
        border-top: none;
        padding-top: 15px;
        padding-bottom: 15px;

        .el-form {
          .el-form-item {
            margin-left: 14px;

            &:first-child {
              .el-form-item__label {
                width: 110px;
              }
            }

            .el-form-item__label {
              text-align: left;
            }

            .el-form-item__content {
              .el-select {
                width: 100%;
              }
            }
          }

          .bcd-params-part {
            margin-left: 26px;

            .bcdpp-title {
              font-weight: bold;
              margin-bottom: 6px;
            }

            .bcdpp-left {
              width: 50%;
              box-sizing: border-box;
              padding-right: 30px;
              border-right: 1px solid #e9e9e9;
            }
          }

          .bcdpp-right {
            width: 50%;
            padding-left: 30px;
            box-sizing: border-box;

            .el-form-item__label {
              &::before {
                content: " ";
              }
            }
          }
        }
      }
    }
  }
}
</style>
