<template>
<div class="batch-ma-in-operate" id="page">
  <el-row class="row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="txtBarCode">
          <el-input v-model="form.txtBarCode" clearable placeholder="试剂条码">
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="检测类型" prop="txtCategoryCode">
          <el-select v-model="form.txtCategoryCode" clearable placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.testTypeOption" :key="index" :label="item.txtCategoryName" :value="item.txtCategoryCode" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
          <el-button type="info" @click="reset()">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" @click="dialog.updateShow=true">新增批次</el-button>
    </el-row>
  </el-row>
  <el-table :data="table.tableData" v-loading="table.tabelLoading">
    <el-table-column prop="txtManufacturerName" label="厂商名称" :show-overflow-tooltip="true" align="center">
      <template #default="scope">{{scope.row.txtManufacturerName||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtCategoryName" label="检测类型名称" align="center">
      <template #default="scope">{{scope.row.txtCategoryName||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtBatchYear" label="生产年份" align="center">
      <template #default="scope">{{scope.row.txtBatchYear||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtBatchMonth" label="生产月份" align="center">
      <template #default="scope">{{scope.row.txtBatchMonth||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtBatchSerial" label="生产批次" align="center">
      <template #default="scope">{{scope.row.txtBatchSerial||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtBarCode" label="试剂条码" align="center">
      <template #default="scope">{{scope.row.txtBarCode||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="dtCreate" label="创建时间" align="center">
      <template #default="scope">{{scope.row.dtCreate||"- -"}}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option" width="300">
      <template #default="scope">
        <div class="table-icon" @click="handleEdit(scope.row)">
          <el-icon>
            <EditPen />
          </el-icon> 编辑
        </div>
        <div class="table-icon" @click="handleCurve(scope.row)">
          <el-icon>
            <Tools />
          </el-icon> 曲线管理
        </div>
        <div class="table-icon" @click="handleDownloadBarCode(scope.row)">
          <el-icon>
            <Download />
          </el-icon> 下载条码
        </div>
        <div class="table-icon" @click="handleDelete(scope.row)">
          <el-icon>
            <Delete />
          </el-icon> 删除
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <update-batch v-if="dialog.updateShow" :updateData="dialog.updateData" @close="(e) => {dialog.updateData={type:'add'};dialog.updateShow=false;if(e){getList()}}" />
  <curve-batch v-if="dialog.curveShow" :curveData="dialog.curveData" @close="() => {dialog.curveShow=false;getList()}" />
  <download-batch v-if="dialog.barShow" :barData="dialog.barData" @close="()=>{dialog.barShow=false;dialog.barData={};}" />
</div>
</template>

<script>
// 运营管理 - 批次管理
import { useStore } from 'vuex';
import { Pagination } from "components";
import CurveBatch from "./comp/batch/curveBatch.vue";
import UpdateBatch from "./comp/batch/updateBatch.vue";
import { onMounted, reactive, toRefs } from 'vue';
import DownloadBatch from "./comp/batch/downloadBatch.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { EditPen, Delete, Tools, Download } from "@element-plus/icons-vue";
import { getCategoryList, getBatchList, getBatchDetail, deleteBatch } from "api/apis.js";
export default {
  components: {
    Pagination,
    EditPen,
    Delete,
    Tools,
    Download,
    UpdateBatch,
    CurveBatch,
    DownloadBatch
  },
  setup() {
    const store = useStore();
    const state = reactive({
      form: {
        txtBarCode: "",
        dateRange: [],
        txtCategoryCode: "",
        limit: 10,
        page: 1
      },
      options: {
        testTypeOption: []
      },
      table: {
        tableData: [],
        total: 0,
        tabelLoading: false
      },
      dialog: {
        updateShow: false,
        updateData: { type: "add" },
        curveShow: false,
        curveData: {},
        barShow: false,
        barData: {}
      }
    });

    onMounted(() => {
      getCategoryOption();
      getList();
    })

    // 获取检测类型 option
    const getCategoryOption = () => {
      getCategoryList().then(response => {
        if (response.code == 200) {
          state.options.testTypeOption = response.data;
          state.options.testTypeOption.unshift({lxcgCategoryId: "", txtCategoryCode: "", txtCategoryName: "全部"});
        } else {
          ElMessage.error(response.message);
          state.options.testTypeOption = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.tabelLoading = true;
      let param = {
        pageNum: state.form.page,
        pageSize: state.form.limit,
        query: {
          txtBarCode: state.form.txtBarCode,
          txtCategoryCode: state.form.txtCategoryCode,
          dtFrom: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[0],
          dtTo: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[1]
        }
      }
      getBatchList(param).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data.list;
          state.table.total = response.data.total;
        } else {
          state.table.tableData = response.data.list;
          state.table.total = response.data.total;
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 下载条码
    const handleDownloadBarCode = row => {
      state.dialog.barData = row;
      state.dialog.barShow = true;
    };

    // 删除批次
    const handleDelete = row => {
      // 删除前判断是否存在曲线，有曲线的不能删
      state.table.tabelLoading = true;
      // 根据 ID 获取 批次详情
      getBatchDetail({ lxcgBatchId: row.lxcgBatchId }).then(response => {
        if (response.code == 200) {
          // 该批次已经配置过曲线 hasCurve == true
          if (response.data.hasCurve) {
            state.table.tabelLoading = false;
            ElMessageBox.confirm(
              "仍存在已配置的曲线，不可删除",
              '提示', {
                showCancelButton: false,
                confirmButtonText: '确定'
              }
            ).then(() => {})
          } else {
            // 没配置过曲线，二次询问
            ElMessageBox.confirm(
              `请确认是否删除当前批次【${row.txtBarCode}】试剂？`,
              '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定'
              }
            ).then(() => {
              // 删除
              state.table.tabelLoading = true;
              deleteBatch({
                lxcgBatchId: row.lxcgBatchId,
                txtUpdater: store.getters.userInfo ? store.getters.userInfo.name : "系统用户"
              }).then(res => {
                if (res.code == 200) {
                  ElMessage.success("删除成功");
                  getList();
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.table.tabelLoading = false;
              })
            }).catch(() => {
              state.table.tabelLoading = false;
            })
          }
        }
      })
    };

    // 编辑批次
    const handleEdit = row => {
      row.type = "edit";
      state.dialog.updateData = row;
      state.dialog.updateShow = true;
    };

    // 曲线管理
    const handleCurve = row => {
      state.dialog.curveData = row;
      state.dialog.curveShow = true;
    };

    // 重置表单
    const reset = () => {
      state.form = {
        txtBarCode: "",
        dateRange: [],
        txtCategoryCode: "",
        limit: 10,
        page: 1
      }
      getList();
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      handleEdit,
      getCategoryOption,
      handleDownloadBarCode,
      handleCurve,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.batch-ma-in-operate {
  .el-table {
    height: calc(100% - 95px);
  }
}
</style>
