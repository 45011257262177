<template>
<el-dialog v-model="visible" :title="title" custom-class="batch-update-dialog" :close-on-click-modal="false" :before-close="close">
  <el-form :model="form" :class="form.hasCurve?'noBorder':''" ref="ruleFormRef" :rules="rules" v-loading="loading">
    <div class="bud-title">基本信息</div>
    <el-form-item label="选择供应商：" prop="lxcgManufacturerId">
      <el-select v-model="form.lxcgManufacturerId" :disabled="formDisable" placeholder="请选择供应商" size="small">
        <el-option v-for="(item, index) in options.manufacturerOption" :key="index" :label="item.txtManufacturerName" :value="item.lxcgManufacturerId" />
      </el-select>
    </el-form-item>
    <el-form-item label="选择检测类型：" prop="lxcgCategoryId">
      <el-select v-model="form.lxcgCategoryId" :disabled="formDisable" placeholder="请选择检测类型" size="small">
        <el-option v-for="(item, index) in options.testTypeOption" :key="index" :label="item.txtCategoryName" :value="item.lxcgCategoryId" />
      </el-select>
    </el-form-item>
    <div class="bud-title">批次信息</div>
    <el-form-item label="生产年份：" prop="txtBatchYear">
      <el-input v-model="form.txtBatchYear" :disabled="formDisable" placeholder="请填写生产年份" maxlength="4" />
    </el-form-item>
    <el-form-item label="生产月份：" prop="txtBatchMonth">
      <el-input v-model="form.txtBatchMonth" :disabled="formDisable" placeholder="请填写生产月份" />
    </el-form-item>
    <el-form-item label="生产批次：" prop="txtBatchSerial">
      <el-input v-model="form.txtBatchSerial" :disabled="formDisable" placeholder="请填写生产批次" />
    </el-form-item>
    <el-form-item label="预计生产数量：" prop="txtBatchDesc">
      <el-input v-model="form.txtBatchDesc" placeholder="请填写预计生产数量" />
    </el-form-item>
  </el-form>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close('cancel')">取 消</el-button>
      <el-button type="primary" @click="close('confirm')">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 批次管理 - 新增/编辑
import { useStore } from 'vuex';
import { ElMessage, FormInstance, } from 'element-plus';
import { reactive, toRefs, ref, computed, onMounted } from "vue";
import { getManufacturerList, getCategoryList, addBatch, getBatchDetail, updateBatch } from "api/apis.js";
export default {
  props: {
    updateData: {
      type: Object,
      default: function () {
        return { type: "add" };
      }
    },
  },
  setup(prop, ctx) {
    const store = useStore();
    // 表单校验 - 生产年份/生产月份/生产批次
    const txtBatchPattern = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/;
      if (rule.field == "txtBatchYear") {
        // 生产年份 - 必须4位数字
        if ((value || "").length < 4 || !reg.test(value)) {
          callback(new Error("请填写有效的生产年份"));
        } else if (parseFloat(value) - parseFloat("2022") < 0) {
          // 生产年份不可小于2022年，只能大于等于2022
          callback(new Error("生产年份不可早于2022年"));
        } else if (parseFloat("2050") - parseFloat(value) < 0) {
          // 生产年份不可晚于2050
          callback(new Error("生产年份不可晚于2050年"));
        } else {
          callback();
        }
      } else if (rule.field == "txtBatchMonth") {
        // 生产月份 - 1-12 之间的数字
        reg = /^[1-9]\d*$/;
        if (reg.test(value) && parseInt(value) < 13) {
          callback();
        } else {
          callback(new Error("请填写有效的生产月份"));
        }
      } else {
        // 生产批次 - 0-9 之间的数字
        reg = /^[0-9]\d*$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请填写有效的生产批次"));
        }
      }
    };

    const state = reactive({
      ruleFormRef: ref(FormInstance),
      visible: true,
      loading: false,
      formDisable: computed(() => state.form.hasCurve),
      title: computed(() => prop.updateData.type == "edit" ? "编辑批次 - " + prop.updateData.txtBarCode : "新增批次"),
      form: {
        lxcgManufacturerId: "",
        lxcgCategoryId: ""
      },
      options: {
        manufacturerOption: [],
        testTypeOption: []
      },
      rules: {
        lxcgManufacturerId: [{ required: true, message: "供应商不可为空", trigger: "blur" }],
        lxcgCategoryId: [{ required: true, message: "检测类型不可为空", trigger: "blur" }],
        txtBatchYear: [
          { required: true, message: "生产年份不可为空", trigger: "blur" },
          { required: true, validator: txtBatchPattern, trigger: "blur" }
        ],
        txtBatchMonth: [
          { required: true, message: "生产月份不可为空", trigger: "blur" },
          { required: true, validator: txtBatchPattern, trigger: "blur" }
        ],
        txtBatchSerial: [
          { required: true, message: "生产批次不可为空", trigger: "blur" },
          { required: true, validator: txtBatchPattern, trigger: "blur" }
        ]
      }
    });

    onMounted(() => {
      // 编辑情况 获取详情
      if (prop.updateData.type == "edit") {
        getDetail();
      } else {
        // 新增情况 直接调用 获取供应商 接口
        getManufacturerOption();
      }
    })

    // 获取 批次详情
    const getDetail = () => {
      state.loading = true;
      getBatchDetail({ lxcgBatchId: prop.updateData.lxcgBatchId }).then(response => {
        if (response.code == 200) {
          state.form = response.data;
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getManufacturerOption();
      })
    };

    // 获取供应商 option
    const getManufacturerOption = () => {
      state.loading = true;
      getManufacturerList().then(response => {
        if (response.code == 200) {
          state.options.manufacturerOption = response.data;
        } else {
          ElMessage.error(response.message);
          state.options.manufacturerOption = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getCategoryOption();
      })
    };

    // 获取检测类型 option
    const getCategoryOption = () => {
      getCategoryList().then(response => {
        if (response.code == 200) {
          state.options.testTypeOption = response.data;
        } else {
          ElMessage.error(response.message);
          state.options.testTypeOption = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const close = (type) => {
      if (type == "confirm") {
        state.ruleFormRef.validate(valid => {
          if (valid) {
            // 校验成功
            state.loading = true;
            let param = {
              lxcgManufacturerId: state.form.lxcgManufacturerId,
              lxcgCategoryId: state.form.lxcgCategoryId,
              txtBatchYear: state.form.txtBatchYear,
              txtBatchMonth: state.form.txtBatchMonth,
              txtBatchSerial: state.form.txtBatchSerial,
              txtBatchDesc: state.form.txtBatchDesc,
            };
            // 判断是新增还是编辑
            if (prop.updateData.type == "add") {
              // 是新增
              param.txtCreator = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              addBatch(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("新增成功");
                  setTimeout(() => {
                    ctx.emit("close", true);
                  }, 1000);
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            } else {
              // 是编辑
              param.txtUpdater = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              param.lxcgBatchId = state.form.lxcgBatchId;
              updateBatch(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("编辑成功");
                  setTimeout(() => {
                    ctx.emit("close", true);
                  }, 1000);
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            }
          }
        });
      } else {
        ctx.emit("close", false);
      }
    };

    return {
      ...toRefs(state),
      close,
      getManufacturerOption,
      getCategoryOption,
      getDetail
    }
  }
}
</script>

<style lang="scss">
.batch-update-dialog {
  width: 40%;

  .bud-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .el-form {
    .el-form-item {
      margin-left: 14px;

      .el-form-item__label {
        width: 130px;
        text-align: left;
      }

      .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
    }

    &.noBorder {
      .el-form-item {
        &.is-required {

          .el-form-item__label {
            &::before {
              content: " ";
            }
          } 
          .el-form-item__content {
            .el-input__wrapper {
              box-shadow: none;
              background-color: transparent;

              .el-input__inner {
                color: #606266;
              }
            }

            .el-input__suffix {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
